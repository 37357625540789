var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-tabs",
    { attrs: { color: "primary", "slider-color": "orange", grow: "" } },
    [
      _c("v-tab", [_vm._v("\n    Søk\n  ")]),
      _c("v-tab-item", [_c("customer-list", { key: "search" })], 1),
      _c("v-tab", [_vm._v("\n    Kunder med feil saldo\n  ")]),
      _c(
        "v-tab-item",
        [
          _c("customer-list", {
            key: "balance-mismatch",
            attrs: { path: "minuteBalanceMismatches" }
          })
        ],
        1
      ),
      _c("v-tab", [_vm._v("\n    Kunder med negativ saldo\n  ")]),
      _c(
        "v-tab-item",
        [
          _c("customer-list", {
            key: "negative-balance",
            attrs: { path: "negativeBalances" }
          })
        ],
        1
      ),
      _c("v-tab", [_vm._v("\n    Kunder med positiv saldo\n  ")]),
      _c(
        "v-tab-item",
        [
          _c("customer-list", {
            key: "positive-balance",
            attrs: { path: "positiveBalances" }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }